import React from 'react'
import { rhythm } from '../typography'

export default ({ image, title, text }) => (
  <div css={{ textAlign: 'center', clear: 'both' }}>
    <img
      alt=""
      css={{
        maxWidth: '100%',
        width: 200,

        '@media (min-width: 600px)': {
          float: 'left',
          marginRight: rhythm(1),
        },
      }}
      src={image}
    />
    <div
      css={{
        textAlign: 'left',
      }}
    >
      <h3 css={{ marginBottom: rhythm(0.5) }}>{title}</h3>
      <p>{text}</p>
    </div>
  </div>
)
