import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Item from '../components/prominent-item'

const ProminentPageTemplate = ({ introduction, items }) => (
  <Layout>
    <p>{introduction}</p>
    {items.map(({ image, text, title }) => (
      <Item image={image} text={text} title={title} />
    ))}
  </Layout>
)

export default ({ data }) => {
  const { introduction, items } = data.pagesYaml
  return <ProminentPageTemplate introduction={introduction} items={items} />
}

export const pageQuery = graphql`
  query ProminentPage($id: String!) {
    pagesYaml(id: { eq: $id }) {
      introduction
      items {
        image
        title
        text
      }
    }
  }
`
